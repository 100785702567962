App.handleSearch = function() {
	$('.js--searchtoggler').on('click', function(e){
		e.preventDefault();
		$('.searchbar').toggleClass('is--open');
		$('.searchbar__input').focus();
	});

	$('.wrapper').on('click', function(){
		if($('.searchbar').hasClass('is--open'))
			$('.searchbar').removeClass('is--open');
	});
};